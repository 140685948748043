import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { MdOutlineSpeakerNotes } from 'react-icons/md';

import {
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  CreateButton,
  DeleteConfirmButton,
  DataTable,
} from '../shared/ReactToolbox';
import { mapToProps, actions } from '../../redux/Factory';
import FieldsFormField from '../fields/FieldsFormField';
import { getAnswerColumns } from './Answer';

const AnswersList = props => {
  const [showNewModal, setShowModal] = useState(false);
  const [answerInEditModal, setAnswerInEditModal] = useState(null);

  const {
    getAllAnswersIsLoading,
    answersList,
    createAnswer,
    createAnswerIsLoading,
    updateAnswer,
    updateAnswerIsLoading,
    deleteAnswer,
    deleteAnswerIsLoading,
    choice,
    questionnaire,
  } = props;
  const { fieldDefinitionsList } = useSelector(mapToProps.fieldDefinitions);

  if (getAllAnswersIsLoading) return null;
  
  const formFields = {
    name: { label: 'Naam' },
    fields: {
      component: props =>
        <FieldsFormField
          answer={answerInEditModal && { ...answerInEditModal }}
          questionnaire={questionnaire}
          {...props}
        />,
    }
  };
  
  return (
    <div style={{ marginLeft: '20px' }}>
      {answersList &&
        <DataTable
          style={{ marginTop: '5px', cursor: 'pointer'}}
          showHeader={false}
          onClickRow={answer => setAnswerInEditModal(answer)} 
          columns={
          [
            ...getAnswerColumns({ questionnaire, fieldDefinitionsList }),
            {
              name: 'Acties',
              selector: answer =>
                <div className='float-end'>
                  <DeleteConfirmButton
                    modalTitle="Verwijder antwoord"
                    onDelete={() => deleteAnswer(answer)}
                    loading={deleteAnswerIsLoading}
                    className="float-end"
                  />
                </div>
            },
          ]}
          data={Object.values(answersList)}
        />
      }
      <CreateButton 
        style={{ marginTop: '5px'}}
        onClick={() => setShowModal(true)}
      >
        {/* <MdOutlineSpeakerNotes /> */}
      </CreateButton>
      
      {answerInEditModal &&
        <EditModal
          dialogClassName="mw-100 w-50"
          modalTitle="Antwoord bewerken"
          loading={updateAnswerIsLoading}
          onHide={() => setAnswerInEditModal(null)}
          initialState={{ ...answerInEditModal, choice: choice.id }}
          formFields={formFields}

          onSave={answer => updateAnswer(
            answer,
            { callback: () => setAnswerInEditModal(null) }
          )}
        />
      }
      
      <CreateModal
        dialogClassName="mw-100 w-50"
        show={showNewModal}
        modalTitle="Nieuw antwoord"
        loading={createAnswerIsLoading}
        onHide={() => setShowModal(false)}
        includeData={{ questionnaire: props.questionnaire.id}}
        initialState={{ name: '', description: '', choice: choice.id, source_description: '' }}
        formFields={formFields}

        onSave={newAnswer => createAnswer(
          newAnswer,
          { callback: () => setShowModal(false) }
        )}
      />
    </div>
  );
}
AnswersList.propTypes = {
  choice: PropTypes.object.isRequired,
}

export default connect(mapToProps.answers, actions.answers)(AnswersList);