import { useSelector } from 'react-redux';
import _ from 'lodash';

import { mapToProps } from '../../redux/Factory';
import FieldDefinition from './FieldDefinition';

const FieldDefinitionsFormField = ({ value = [], onChange }) => {
  // Either select one fieldDefinition or multiple fieldDefinitions depending of value prop is an array or not.

  const { fieldDefinitionsList } = useSelector(mapToProps.fieldDefinitions);

  const fieldDefinitionsArray = value
    .filter(id => !!fieldDefinitionsList[id])
    .map(id => fieldDefinitionsList[id]);
  Object.values(fieldDefinitionsList).map(fd => {
    if (!value.find(id => id === fd.id)) {
      fieldDefinitionsArray.push(fd);
    }
  })

  return (
    <div className="form-control-lg">
      {fieldDefinitionsArray.map((fieldDefinition, key) => {
        const hasFieldDefinition = !!value.find(id => id === fieldDefinition.id);
        return (
          <FieldDefinition
            key={key}
            hasFieldDefinition={hasFieldDefinition}
            cursor='pointer'
            className='fieldDefinition'
            onClick={() => {
              if (hasFieldDefinition) {
                onChange(value.filter(id => id !== fieldDefinition.id));
              } else {
                onChange([...value, fieldDefinition.id]);
              }
            }}
          >
            {fieldDefinition.name}
          </FieldDefinition>
        );
      })}
    </div>
  );
}
export default FieldDefinitionsFormField;