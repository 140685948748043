import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate  } from 'react-router-dom';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import axios from '../../utils/Axios';

import { login } from '../../redux/actions/Login.js';

class Login extends Component {
  constructor(props) {
  super(props);
  this.state = {
    email: '',
    password: ''
  };
  }
  onChange = e => {
  this.setState({ [e.target.name]: e.target.value });
  };

  onLoginClick = () => {
  const userData = {
    email: this.state.email,
    password: this.state.password
  };
  this.props.login(userData);
  };
  render() {
  if (this.props.auth.isAuthenticated) {
    return <Navigate to='/' />
  }
  return (
    <Container>
    <Row className="login">
      <Col md='2'>
      </Col>
      <Col md='8'>
      <h1>Inloggen</h1>
      <Form>
        <Form.Group className="mb-3" controlId='emailId'>
        <Form.Label>Emailadres</Form.Label>
        <Form.Control
          type='text'
          name='email'
          placeholder='Emailadres'
          value={this.state.email}
          onChange={this.onChange}
        />
        </Form.Group>

        <Form.Group className="mb-3" controlId='passwordId'>
        <Form.Label>Wachtwoord</Form.Label>
        <Form.Control
          type='password'
          name='password'
          placeholder='Wachtwoord'
          value={this.state.password}
          onChange={this.onChange}
        />
        </Form.Group>
      </Form>
      <Button variant='primary' onClick={this.onLoginClick}>
        Inloggen
      </Button>
      <p className='mt-2'>
        Nieuw wachtwoord aanmaken of wachtwoord vergeten? &nbsp;
        <a href={`${axios.defaults.baseURL}/accounts/password/reset/`}>Wachtwoord resetten</a>
      </p>
      </Col>
      <Col md='2'>
      </Col>
    </Row>
    </Container>
  );
  }
}

//export default Login;
Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  login
})(Login);
