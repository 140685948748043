import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  SmallSpinner,
} from '../shared/ReactToolbox';
import { mapToProps, actions, use } from '../../redux/Factory';
import ChoicesList from '../choices/ChoicesList';

const Questionnaire = () => {
  const { id } = useParams()
  const { choicesList, clearAllChoices, getAllChoices, getAllChoicesIsLoading } = use.choices();
  const { clearAllAnswers, getAllAnswers, getAllAnswersIsLoading, answersList } = use.answers();
  const { fieldDefinitionsList } = use.fieldDefinitions();
  const { selectionOptionsList } = use.selectionOptions();
  const { getQuestionnaire, questionnaire } = use.questionnaires({ id });

  useEffect(() => {
    if (!id) return;
    clearAllChoices();
    getAllChoices({ params: { questionnaire: id } });

    clearAllAnswers();
    
    getQuestionnaire(id);
    getAllAnswers({ params: { questionnaire: id } });
  }, [id]);

  
  if (
    getAllChoicesIsLoading
    || getAllAnswersIsLoading
    || !questionnaire
    || !choicesList
    || !answersList
    || !fieldDefinitionsList
    || !selectionOptionsList
  ) return <SmallSpinner className='vertical-space' />;

  return (
    <div style={{ marginRight: '20px' }}>
      <ChoicesList questionnaire={questionnaire} parent={null} />;
    </div>
  );
};

export default Questionnaire;