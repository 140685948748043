import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  DataTable,
  EditButton,
  CreateButton,
  SmallSpinner,
  DeleteConfirmButton,
} from '../shared/ReactToolbox';
import FieldDefinitionsFormField from '../fields/FieldDefinitionsFormField';
import { mapToProps, actions } from '../../redux/Factory';

const QuestionnairesList = connect(
  (state, ownProps) => ({
    ...mapToProps.questionnaires(state, ownProps),
    ...mapToProps.fieldDefinitions(state, ownProps),
  }),
  {
    ...actions.questionnaires,
  }
)(props => {
  const formFields = {
    name: { label: 'Naam' },
    choices_count: {
      label: 'Aantal vragen',
      placeholder: <i>Onbeperkt</i>,
    },
    field_definitions: {
      label: 'Invoervelden',
      component: FieldDefinitionsFormField,
      initialValue: [],
    }
  }
  const [createModalActive, setCreateModalActive] = useState(false);
  const [questionnaireInEditModal, setQuestionnaireInEditModal] = useState(false);

  const setChoicesCount = ({ choices_count, ...questionnaire }) => ({
    choices_count: 
      choices_count && parseInt(choices_count)
        ? parseInt(choices_count)
        : null,
    ...questionnaire,
  });
  return (
    <Container className='vertical-space'>

      {!props.questionnairesList || !props.fieldDefinitionsList
        ? <SmallSpinner />
        : <>
            <h2>Vragenlijsten</h2>
            <DataTable
              showHeader={false}
              filterColumn='name'
              columns={[
                {
                  name: '#',
                  orderBy: ({ order }) => order,
                  selector: ({ order }) => order + 1,
                },
                {
                  orderBy: 'name',
                  name: 'Naam',
                  selector: ({ name, id }) => <Link to={`/${id}`}>{name}</Link>,
                },
                {
                  name: 'Aantal vragen',
                  selector: ({ choices_count }) => choices_count || <i>Onbeperkt</i>,
                },
                {
                  name: 'Acties',
                  selector: questionnaire =>
                    <div className='float-end'>
                      <EditButton onClick={() => setQuestionnaireInEditModal(questionnaire)} />
                      <DeleteConfirmButton onDelete={() => props.deleteQuestionnaire(questionnaire)} />
                    </div>,
                },
              ]}
              data={Object.values(props.questionnairesList)}
              onMove={({ item, target, reset }) => props.moveQuestionnaire(
                {
                  questionnaire: item,
                  target,
                  position: item.order > target.order ? 'right' : 'left',
                },
                {
                    callback: reset,
                    onError: reset,
                }
              )}
              orderByDefault='order'
            />

            <CreateButton onClick={() => setCreateModalActive(true)} />
          </>
      }

      {questionnaireInEditModal &&
        <EditModal
          show={!!questionnaireInEditModal}
          modalTitle="Vragenlijst bewerken"
          loading={props.updateQuestionnaireIsLoading}
          onHide={() => setQuestionnaireInEditModal(null)}
          initialState={questionnaireInEditModal}
          formFields={formFields}

          onSave={questionnaire => props.updateQuestionnaire(
            setChoicesCount(questionnaire),
            { callback: () => setQuestionnaireInEditModal(null) },
          )}
        />
      }
      
      {createModalActive &&
        <CreateModal
          modalTitle="Nieuwe vragenlijst"
          loading={props.createQuestionnaireIsLoading}
          onHide={() => setCreateModalActive(false)}
          formFields={formFields}

          onSave={questionnaire => props.createQuestionnaire(
            setChoicesCount(questionnaire),
            { callback: () => setCreateModalActive(false) }
          )}
        />
      }
    </Container>
  );
});

export default QuestionnairesList;