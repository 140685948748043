import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Outlet, Link } from 'react-router-dom';
import { Button, Navbar, Nav, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

import { logout } from '../../redux/actions/Login';

import { mapToProps, actions } from '../../redux/Factory';

const Dashboard = props => {
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    props.getQuestionnairesList();
    console.log({ props })
    props.getFieldDefinitionsList();
    props.getAllSelectionOptions();
  }, []);

  const { user } = props.auth;
  return (
  <div>
    <Navbar bg='light'>
    <Navbar.Brand><Link to='/'>Dashboard</Link></Navbar.Brand>
    <Nav className="mr-auto">
      {/* <Button
      variant="light"
      onClick={() => setModalActive('vatDeductables')}
      >
      BTW aftrekbaar
      </Button>
      
      <Button
      variant="light"
      onClick={() => setModalActive('taxCosts')}
      >
      Kosten IB
      </Button>
      
      <Button
      variant="light"
      onClick={() => setModalActive('categories')}
      >
      Categorieën IB
      </Button> */}
    </Nav>
    <Navbar.Collapse className='justify-content-end'>
      <Navbar.Text>
        User: <b>{user.username}</b>
      </Navbar.Text>
      <Nav.Link onClick={() => props.logout()}>Logout</Nav.Link>
    </Navbar.Collapse>
    </Navbar>
    
    <Outlet />
  </div>
  );
}

Dashboard.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

export default connect(
  (state, ownProps) => ({
    auth: state.auth,
    ...mapToProps.questionnaires(state, ownProps),
    ...mapToProps.fieldDefinitions(state, ownProps),
    ...mapToProps.selectionOptions(state, ownProps),
    ...mapToProps.choices(state, ownProps),
    ...mapToProps.answers(state, ownProps),
  }),
  {
    logout,
    ...actions.answers,
    ...actions.choices,
    ...actions.questionnaires,
    ...actions.fieldDefinitions,
    ...actions.selectionOptions,
}
)(Dashboard);

