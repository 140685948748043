import axios from '../../utils/Axios';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { setAxiosAuthToken, toastOnError } from '../../utils/Utils';

export const LOGIN_SET_TOKEN = 'LOGIN_SET_TOKEN';
export const LOGIN_SET_CURRENT_USER = 'LOGIN_SET_CURRENT_USER';
export const LOGIN_UNSET_CURRENT_USER = 'LOGIN_UNSET_CURRENT_USER';

export const login = (userData, redirectTo) => async dispatch => {
  try {
    const response = await axios.post('/api/token/login/', userData);
    const { auth_token } = response.data;
    setAxiosAuthToken(auth_token);
    dispatch(setToken(auth_token));
    dispatch(getCurrentUser(redirectTo));
  } catch(error) {
    dispatch(unsetCurrentUser());
    toastOnError(error);
  };
};

export const getCurrentUser = redirectTo => async dispatch => {
  try {
    const response = await axios.get('/api/users/me/');
    const user = {
      username: response.data.username,
      email: response.data.email,
    };
    dispatch(setCurrentUser(user, redirectTo));
  } catch(error) {
    dispatch(unsetCurrentUser());
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.hasOwnProperty('data') &&
        error.response.data.hasOwnProperty('detail') &&
        error.response.data['detail'] === 'User inactive or deleted.'
      ) {
        dispatch(push('/resend_activation'));
      }
    } else {
        toastOnError(error);
    }
  };
};

export const setCurrentUser = (user, redirectTo) => dispatch => {
  localStorage.setItem('wizard-user', JSON.stringify(user));
  dispatch({
    type: LOGIN_SET_CURRENT_USER,
    payload: user,
  });

  if (redirectTo !== '') {
    dispatch(push(redirectTo));
  }
};

export const setToken = token => dispatch => {
  setAxiosAuthToken(token);
  localStorage.setItem('wizard-token', token);
  dispatch({
    type: LOGIN_SET_TOKEN,
    payload: token,
  });
};

export const unsetCurrentUser = () => dispatch => {
  setAxiosAuthToken('');
  localStorage.removeItem('wizard-token');
  localStorage.removeItem('wizard-user');
  dispatch({
    type: LOGIN_UNSET_CURRENT_USER
  });
};

export const logout = () => async dispatch => {
  try {
    const response = await axios.post('/api/token/logout/');
    dispatch(unsetCurrentUser());
    dispatch(push('/'));
    toast.success('Logout successful.');
  } catch(error) {
    dispatch(unsetCurrentUser());
    toastOnError(error);
  };
};
