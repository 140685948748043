
import { connect } from 'react-redux';
import React, { Component, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ListGroup, Form } from 'react-bootstrap';
import _ from 'lodash';

import { mapToProps, actions } from '../../redux/Factory';
import {
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  DragAndDropList,
  CreateButton,
  DeleteConfirmButton,
  SmallSpinner,
} from '../shared/ReactToolbox';

export const formatSelection = ({ name, description }) => <span>{name}{description && <>&nbsp;<i>({description})</i></>}</span>

const Selection = ({ selectionOption, onUpdate, onDelete }) => {
  const [nameModified, setNameModified] = useState(null);
  const [descriptionModified, setDescriptionModified] = useState(null);
  return <>
  {nameModified === null
    ? <div>
        {formatSelection(selectionOption)}
        <DeleteConfirmButton
        onDelete={() => onDelete(selectionOption)}
        className="float-end"
        />
      </div>
    : <>
        <Form.Control
        as="input"
        autoComplete="off"
        value={nameModified}
        onChange={e => setNameModified(e.target.value) }
        onKeyPress={e => {
          if (e.charCode === 13) {
            // Pressing the enter key will save data unless it is a multi line text area
            e.preventDefault();
            onUpdate(
              {
                ...selectionOption,
                name: nameModified,
                description: descriptionModified,
              },
              {
                callback: () => {
                  setNameModified(null);
                  setDescriptionModified(null);
                }
              }
            );
          }
        }}
        />
        <Form.Control
        as="textarea"
        autoComplete="off"
        value={descriptionModified}
        onChange={e => setDescriptionModified(e.target.value) }
        />
      </>
    }
  </>;
}
class SelectionOptionsModal extends Component {
  static propTypes = {
    getSelectionOptionsList: PropTypes.func.isRequired,
    createSelectionOption: PropTypes.func.isRequired,
    updateSelectionOption: PropTypes.func.isRequired,
    deleteSelectionOption: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    field_definition: PropTypes.object.isRequired,
  }

  static initialState = {
    editModalActive: false,
    newSelectionModal: false,
    selectionOptionInEditModal: null,
    droppedItem: null,
  }
  state = SelectionOptionsModal.initialState;

  render() {
    if (this.props.getListIsLoading) return null;
    const {
      selectionOptionsList,
      createSelectionOptionIsLoading,
      updateSelectionOptionIsLoading,
      moveSelectionOptionIsLoading,
      deleteSelectionOptionIsLoading,
    } = this.props;
    const { newSelectionModal, newName } = this.state;
    
    const formFields = {
      name: { label: 'Naam' },
      description: { label: 'Toelichting' },
    }

    const component = forwardRef(({ selectionOption, dropped, onClick, isLoading, ...restProps }, ref) =>
      <ListGroup.Item
        {...restProps}
        ref={ref}
        onClick={() => this.setState({ selectionOptionInEditModal: selectionOption })}
      >
        <Selection
          // onClick={() => console.log({ selectionOption, dropped, onClick, isLoading, ...restProps })}
          selectionOption={selectionOption}
          onUpdate={this.props.updateSelectionOption}
          onDelete={this.props.deleteSelectionOption}
          isLoading={dropped && moveSelectionOptionIsLoading}
        />
      </ListGroup.Item>
    );

    const selectionsArray = selectionOptionsList && Object.values(selectionOptionsList)
      .sort(({ order: a }, { order: b }) => a - b)
      .map((selectionOption) => ({ selectionOption }));
    return (
      <Modal
        show={this.props.show !== false}
        onHide={() => {
          this.setState(SelectionOptionsModal.initialState);
          this.props.onHide();
        }}
        centered
        dialogClassName="mw-100 w-50"
        >
        <Modal.Header closeButton>
          <Modal.Title>Selecties bewerken</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ListGroup>
            <DragAndDropList
              component={component}
              propsArray={selectionsArray || []}
              // onDrop={({ item, target, reset }) => {
              //   this.props.moveSelectionOption(
              //     item,
              //     {
              //       args: { 
              //         target,
              //         position: item.order > target.order ? 'right' : 'left',
              //       },
              //       callback: reset,
              //       onError: reset,
              //     }
              //   );
              // }}
              onDrop={(index, targetIndex, reset ) => {
                const selectionOption = selectionsArray[index].selectionOption;
                const target = selectionsArray[targetIndex].selectionOption;
                console.log({selectionOption, target})
                if (selectionOption.id === target.id || moveSelectionOptionIsLoading) {
                  return;
                }
                this.props.moveSelectionOption(
                  selectionOption,
                  {
                    args: { 
                      target,
                      position: selectionOption.order > target.order ? 'right' : 'left',
                    },
                    callback: reset,
                    onError: reset,
                  }
                );
              }}
            />
          </ListGroup>
          <div style={{ marginTop: '10px' }}>
            {!newSelectionModal &&
              <CreateButton
              onClick={() => this.setState({ newSelectionModal: true })}
              className="float-end"
              />
            }
            {newSelectionModal &&
              <CreateModal
                modalTitle="Nieuwe selectie"
                loading={createSelectionOptionIsLoading}
                onHide={() => this.setState({ newSelectionModal: false })}
                includeData={{ field_definition: this.props.field_definition.id }}
                initialState={{ name: '', description: '' }}
                formFields={formFields}
          
                onSave={selectionOption => this.props.createSelectionOption(
                  selectionOption,
                  { callback: () => this.setState({ newSelectionModal: false }) }
                )}
              />
            }
          </div>
          {this.state.selectionOptionInEditModal &&
            <EditModal
              modalTitle="Bewerken"
              loading={updateSelectionOptionIsLoading}
              onHide={() => this.setState({ selectionOptionInEditModal: null })}
              initialState={{ ...this.state.selectionOptionInEditModal }}
              formFields={formFields}

              onSave={selectionOption => this.props.updateSelectionOption(
                selectionOption,
                { callback: () => this.setState({ selectionOptionInEditModal: null }) }
              )}
            />
          }
        </Modal.Body>

        <Modal.Footer>
          {(this.props.loading || createSelectionOptionIsLoading || updateSelectionOptionIsLoading || deleteSelectionOptionIsLoading ) && <SmallSpinner />}
          <Button
            variant="secondary"
            onClick={this.props.onHide}
          >
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(
  mapToProps.selectionOptions,
  actions.selectionOptions
)(SelectionOptionsModal);