import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CgNotes } from 'react-icons/cg';
import { BsPencil } from 'react-icons/bs';
import { HiOutlineLink } from 'react-icons/hi';

import { mapToProps, actions } from '../../redux/Factory';
import {
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  DataTable,
  EditButton,
  NotesButton,
  CreateButton,
  SmallSpinner,
  DeleteConfirmButton,
} from '../shared/ReactToolbox';
import SelectionOptionsModal from './SelectionOptionsModal';

export const fieldDefinitionName = ({ resourcetype }) => ({
    TextFieldDefinition: 'Tekstveld',
    SelectionFieldDefinition: 'Meerkeuzeveld',
    UrlFieldDefinition: 'Hyperlinkveld',
  }[resourcetype] || <i>Niet gevonden</i>
);

const FieldDefinitionsList = connect(
  (state, ownProps) => ({
    ...mapToProps.fieldDefinitions(state, ownProps),
  }),
  {
    ...actions.fieldDefinitions,
  }
)(props => {
  const formFields = {
    name: { label: 'Naam' },
  }
  const [resourcetypeInCreateModal, setResourcetypeInCreateModal] = useState(null);
  const [fieldDefinitionInEditModal, setFieldDefinitionInEditModal] = useState(null);
  const [fieldDefinitionInSelectionOptionsModal, setFieldDefinitionInSelectionOptionsModal] = useState(null);

  const setChoicesCount = ({ choices_count, ...fieldDefinition }) => ({
    choices_count: 
      choices_count && parseInt(choices_count)
        ? parseInt(choices_count)
        : null,
    ...fieldDefinition,
  });
  
  return (
    <Container className='vertical-space'>

      {!props.fieldDefinitionsList
        ? null
        : <>
            <h2>Invoervelden</h2>
            <DataTable
              showHeader={false}
              filterColumn='name'
              columns={[
                {
                  orderBy: 'name',
                  name: 'Type',
                  selector: fd => fieldDefinitionName(fd),
                },
                {
                  orderBy: 'name',
                  name: 'Naam',
                  selector: ({ name }) => name,
                },
                {
                  name: 'Acties',
                  selector: fd => 
                    <div className='float-end'>
                      {fd.resourcetype === 'SelectionFieldDefinition' &&
                        <NotesButton onClick={() => setFieldDefinitionInSelectionOptionsModal(fd)} />
                      }
                      <EditButton onClick={() => setFieldDefinitionInEditModal(fd)} />
                      <DeleteConfirmButton onDelete={() => props.deleteFieldDefinition(fd)} />
                    </div>,
                },
              ]}
              data={Object.values(props.fieldDefinitionsList)}
            />

            <CreateButton onClick={() => setResourcetypeInCreateModal('TextFieldDefinition')}>
              <BsPencil />
            </CreateButton>

            <CreateButton onClick={() => setResourcetypeInCreateModal('SelectionFieldDefinition')}>
              <CgNotes />
            </CreateButton>

            <CreateButton onClick={() => setResourcetypeInCreateModal('UrlFieldDefinition')}>
              <HiOutlineLink />
            </CreateButton>
          </>
      }

      {resourcetypeInCreateModal &&
        <CreateModal
          modalTitle={`${fieldDefinitionName({ resourcetype: resourcetypeInCreateModal })} aanmaken`}
          loading={props.createFieldDefinitionIsLoading}
          onHide={() => setResourcetypeInCreateModal(false)}
          includeData={{
            resourcetype: resourcetypeInCreateModal,
            ...resourcetypeInCreateModal === 'SelectionFieldDefinition' ? { options: [] } : {},
          }}
          formFields={formFields}

          onSave={fieldDefinition => props.createFieldDefinition(
            setChoicesCount(fieldDefinition),
            { callback: () => setResourcetypeInCreateModal(false) }
          )}
        />
      }

      {fieldDefinitionInEditModal &&
        <EditModal
          show={!!fieldDefinitionInEditModal}
          modalTitle={`${fieldDefinitionName(fieldDefinitionInEditModal)} bewerken`}
          loading={props.updateFieldDefinitionIsLoading}
          onHide={() => setFieldDefinitionInEditModal(null)}
          initialState={fieldDefinitionInEditModal}
          formFields={formFields}

          onSave={fieldDefinition => props.updateFieldDefinition(
            setChoicesCount(fieldDefinition),
            { callback: () => setFieldDefinitionInEditModal(null) },
          )}
        />
      }
      {fieldDefinitionInSelectionOptionsModal &&
        <SelectionOptionsModal
          field_definition={fieldDefinitionInSelectionOptionsModal}
          onHide={() => setFieldDefinitionInSelectionOptionsModal(null)}
        />
      }
    </Container>
  );
});

export default FieldDefinitionsList;